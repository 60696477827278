import { useTagsStore } from '@/store/tags'

export default function (to, from, next) {
  const tagsStore = useTagsStore()
  if (to.path === '/login') {
    tagsStore.clearVisitedTags()
    tagsStore.clearCachedTags()
  }
  if (!to.meta.isHidden) {
    tagsStore.addVisitedTags(to)
    tagsStore.addCachedTags(to)
  }
  next()
}