<template>
  <div class="flex bg-white tagBox px-4 w-full">
    <div class="tagContent">
<!--      <el-icon class="tagContent_leftBtn control" @click="handleClickControl('left')"><DArrowLeft /></el-icon>-->
      <el-scrollbar ref="scrollbarRef" class="tagList py-2" horizontal>
        <el-tag
            v-for="item in visitedTags"
            class="border mr-2"
            :key="item.fullPath"
            :type="router.currentRoute.value.fullPath === item.fullPath ? 'primary' : 'info'"
            :effect="router.currentRoute.value.fullPath === item.fullPath ? 'dark' : 'light'"
            :closable="item.name !== 'Dashboard'"
            @close="handleCloseTag(item)"
            @contextmenu.prevent="openMenu(item, $event)"
        >
          <router-link :to="item.fullPath">{{ item.meta.title }}</router-link>
        </el-tag>
      </el-scrollbar>
<!--      <el-icon class="tagContent_rightBtn control" @click="handleClickControl('right')"><DArrowRight /></el-icon>-->
    </div>
    <ul v-show="contentmenuVisible" class="contextMenu" :style="{ left: left + 'px', top: top + 'px' }">
      <li @click="handleRefresh">刷新</li>
    </ul>
  </div>
</template>

<script setup>
import { useTagsStore } from '@/store/tags'
import { computed, ref, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useTagsStore()
const visitedTags = computed(() => store.visitedTags)
const scrollbarRef = ref(null)
const contentmenuVisible = ref(false)
const selectedTag = ref(null)
const left = ref(-100)
const top = ref(-100)

// const handleClickControl = (direction) => {
//   if (direction === 'left') {
//     scrollbarRef.value.setScrollLeft(0)
//   } else {
//     scrollbarRef.value.setScrollLeft(scrollbarRef.value.wrapRef.scrollWidth)
//   }
// }

const openMenu = (tag, e) => {
  top.value = e.clientY - 60
  left.value = e.clientX - 140
  contentmenuVisible.value = true
  selectedTag.value = tag
}
const handleRefresh = () => {
  store.deleteCachedTags(selectedTag.value)
  const { path, query } = selectedTag.value
  nextTick(() => {
    router.replace({
      path: '/redirect' + path,
      query
    })
  })
}

const handleCloseTag = (item) => {
  console.log(item)
  deleteTag()
  function deleteTag() {
    store.deleteVisitedTags(item)
    store.deleteCachedTags(item)
    if (item.fullPath === router.currentRoute.value.fullPath) {
      const latestView = visitedTags.value.slice(-1)[0]
      router.push(latestView.fullPath)
    }
  }
}

const clickEvent = () => {
  if (contentmenuVisible.value) {
    contentmenuVisible.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', clickEvent)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', clickEvent)
})
</script>

<style lang="scss" scoped>
.tagBox {
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  transition: all .2s;
  transform: translateZ(0);
  z-index: 100;
  position: absolute;
  top: var(--itsop-header-height);
  width: 100%;
  .contextMenu {
    z-index: 3000;
    box-shadow: 2px 2px 3px 0 rgba(0,0,0,.3);
    position: absolute;
    font-size: 12px;
    padding: 5px 0;
    color: var(--itsop-font-2);
    border-radius: 4px;
    background: #fff;
    li {
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
  .tagContent {
    width: var(--itsop-content-width);
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    .tagList {
      white-space: nowrap;
      flex: 1;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .control {
      background: white;
      height: 100%;
      width: 2em;
      box-shadow: 0 2px 8px rgba(0,0,0,.2);
      cursor: pointer;
      z-index: 1;
    }
  }
}
.el-tag {
  &.is-closable {
    padding-right: 9px;
  }
}
.el-tag--info {
  background: #ffffff;
  border-radius: 0;
  color: var(--itsop-font-2);
}
.el-tag--primary {
  border-radius: 0;
}
</style>