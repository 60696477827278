import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalStore = defineStore('global', () => {
  const userData = ref(null)

  const setUserData = function(data) {
    userData.value = data
  }

  return {
    userData,
    setUserData
  }
})